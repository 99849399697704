import React from 'react'
import ContactCard from '../cards/ContactCard'

export default function Contact() {
    return (
        <>
         
        <div className='flex items-center justify-center mb-5'>
        <div className='w-[520px] h-62 '>
            <ContactCard/>
         </div>
        </div>
     


        </>
    )
}
